<template>
  <div>
    <div class="row">

      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <ul class="list-group list-group-flush mb-2">
              <li v-if="edit" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">Name</h6>
                <input class="form-control" style="width: 75%;" type="text" v-model="company.name">
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">Street</h6>
                <span v-if="!edit" class="text-white">{{company.street || "N/A"}}</span>
                <input v-else class="form-control" style="width: 75%;" type="text" v-model="company.street">
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">Town</h6>
                <span v-if="!edit" class="text-white">{{company.town || "N/A"}}</span>
                <input v-else class="form-control" style="width: 75%;" type="text" v-model="company.town">
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">County</h6>
                <span v-if="!edit" class="text-white">{{company.county || "N/A"}}</span>
                <input v-else class="form-control" style="width: 75%;" type="text" v-model="company.county">
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">Postcode</h6>
                <span v-if="!edit" class="text-white">{{company.postcode || "N/A"}}</span>
                <input v-else class="form-control" style="width: 75%;" type="text" v-model="company.postcode">
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">Country</h6>
                <span v-if="!edit" class="text-white">{{company.company || "N/A"}}</span>
                <input v-else class="form-control" style="width: 75%;" type="text" v-model="company.country">
              </li>
            </ul>

            <submit-button :background="'btn btn-sm btn-primary ms-2'" style="float: right;" :clicked='updating' @click="saveChanges" :text="'Save Changes'"></submit-button>
            <button class="btn btn-sm" :class="{'btn-light': !edit, 'btn-danger': edit}" @click="edit = !edit" style="float: right;">{{ edit ? 'Stop Editing' : 'Edit' }}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import SubmitButton from "../../../components/SubmitButton";

export default {
  name: 'admin.companies.view',
  props: ['id'],
  components: {
    SubmitButton
  },
  data(){
    return {
      company: {
        name: "",
        street: "",
        town: "",
        county: "",
        postcode: "",
        country: ""
      },
      edit: false,
      updating: false
    }
  },
  mounted(){
    this.loadCompany()
  },
  methods: {
    loadCompany(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/company/${this.id}`)
          .then(response => {
            this.company = response.data.company;
          })
          .catch(error => {
            this.$error("Failed to load company.", error);
          })
    },
    saveChanges(){
      this.updating = true;
      let data = new FormData();
      data.append('name', this.client.name);
      data.append('street', this.client.street);
      data.append('town', this.client.town);
      data.append('county', this.client.county);
      data.append('postcode', this.client.postcode);
      data.append('country', this.client.country);
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/company/${this.id}`, data)
          .then(() => {
            this.$success("Updated company");
            this.updating = false;
            this.edit = false;
            this.loadCompany();
          })
          .catch(error => {
            this.$error("Failed to update company.", error);
            this.updating = false;
          })
    }
  }
}
</script>

<style scoped>

</style>